import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { Client } from '@microsoft/microsoft-graph-client'

import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser'

/**
 * Creates a graph client that can be directly used to make authorized calls against the Microsoft Graph API.
 * @param providerOptions
 * @returns
 */
export const getGraphClient = (
  providerOptions: AuthCodeMSALBrowserAuthenticationProviderOptions,
  instance: IPublicClientApplication
) => {
  const clientOptions = {
    authProvider: new AuthCodeMSALBrowserAuthenticationProvider(
      instance as PublicClientApplication,
      providerOptions
    ),
    baseUrl: 'https://graph.microsoft.com',
  }

  if (
    instance.getConfiguration().auth.authority ===
    process.env.REACT_APP_MSAL_CHINA_AUTHORITY
  ) {
    clientOptions.baseUrl = 'https://microsoftgraph.chinacloudapi.cn'
  }

  const graphClient = Client.initWithMiddleware(clientOptions)
  return graphClient
}

export async function acquireToken(
  instance: IPublicClientApplication,
  scopes: string[],
  account: AccountInfo,
  inProgress: InteractionStatus,
  fn: (token: string) => void
) {
  try {
    const response = await instance.acquireTokenSilent({
      scopes,
      account: account!,
    })
    fn(response.idToken)
  } catch (err) {
    if (!(err instanceof InteractionRequiredAuthError)) throw err
    if (account || inProgress !== 'none')
      throw new Error('account or inProgress')

    const response = await instance.acquireTokenPopup({ scopes })
    fn(response.idToken)
  }
}
