import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import IMsalConfig from './IMsalConfig'
import { MsalRegion } from './msalRegion'

const config: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_MSAL_AUTHORITY ?? '',
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

const msalInstance = new PublicClientApplication(config)

export const msalConfigNonCn: IMsalConfig = {
  region: MsalRegion.NONCN,
  instance: msalInstance,
}
