import { Button, Stack } from 'react-bootstrap'

export default function Start() {

  return <div className='vh-100 mx-auto d-flex align-items-center'>
    <Stack gap={2} style={{ alignSelf: "unset" }}>
      <Button href="/noncn" variant="outline-secondary">Azure Public Cloud (EMEA/US/SK)</Button>
      <Button href="/cn" variant="outline-secondary">Azure China Cloud</Button>
    </Stack>
  </div>
}
