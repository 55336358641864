import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

import { Col, Container, Row } from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import Start from './Start'
import MsalWrapper from './components/MsalWrapper'
import JumpHost from './JumpHost'
import { MsalRegion } from './msal/msalRegion'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Start />,
  },
  {
    path: '/noncn',
    element: <MsalWrapper region={MsalRegion.NONCN}>
      <JumpHost />
    </MsalWrapper>
  },
  {
    path: '/cn',
    element: <MsalWrapper region={MsalRegion.CN}>
      <JumpHost />
    </MsalWrapper>
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Container fluid="sm">
      <Row className="mb-5">
        <Col style={{ textAlign: 'center' }}><h1>Megatron Jump Host Self-Service</h1></Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm="auto">
          <RouterProvider router={router} />
        </Col>
      </Row>
    </Container>
  </React.StrictMode>
)
