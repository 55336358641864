export interface StartData {
  hub: string
  cluster: string
  productStage: string
  vmSize: string
  sshKey: string
  cloudInit: string
}

const host = process.env.REACT_APP_FUNCTION_URL

export async function postStart(data: StartData, accessToken: string) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  return fetch(`${host}/api/start`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  })
}
