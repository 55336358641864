import * as forge from 'node-forge'

import SSHKeyPair from '../models/sshkeypair'

export async function generateSSHKey(): Promise<SSHKeyPair> {
  return new Promise((resolve) => {
    forge.pki.rsa.generateKeyPair(
      { bits: 2048, workers: -1 },
      (err: any, keypair: any) => {
        if (err) throw err

        resolve({
          privateKey: forge.ssh.privateKeyToOpenSSH(keypair.privateKey),
          publicKey: forge.ssh.publicKeyToOpenSSH(keypair.publicKey),
        })
      }
    )
  })
}
