import { Formik, FormikErrors } from 'formik'
import { useState } from 'react'
import {
  Accordion,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Spinner,
} from 'react-bootstrap'
import { downloadString } from '../helper/downloadHelper'

import { generateSSHKey } from '../helper/sshHelper'
import ProductStage from '../models/ProductStage'
import { useMsal } from '@azure/msal-react'

export interface JumpHostFormValues {
  hub: string
  cluster: string
  productStage: number
  vmSize: string
  sshKey: string
  cloudInit: string
}

export interface VmSize {
  name: string
  spec: string
}

export interface JumpHostFormProps {
  stages: ProductStage[]
  handleSubmit: (values: JumpHostFormValues) => void
  provisioning: boolean
}

export default function JumpHostForm({
  stages,
  handleSubmit,
  provisioning,
}: JumpHostFormProps) {
  const { accounts } = useMsal()
  const [isGeneratingSSHKey, setGeneratingSSHKey] = useState(false)

  let vmSizes: VmSize[]
  // different vm sizes for chinese cloud
  if (
    !accounts ||
    accounts.length === 0 ||
    accounts[0].environment !== 'login.partner.microsoftonline.cn'
  ) {
    vmSizes = [
      { name: 'Standard_D2lds_v5', spec: '2 vCPus, 4GB' },
      { name: 'Standard_D2ds_v5', spec: '2 vCPus, 8GB' },
      { name: 'Standard_D4lds_v5', spec: '4 vCPus, 8GB' },
      { name: 'Standard_D4ds_v5', spec: '4 vCPus, 16GB' },
    ]
  } else {
    vmSizes = [
      { name: 'Standard_D2ds_v5', spec: '2 vCPus, 8GB' },
      { name: 'Standard_D4ds_v5', spec: '4 vCPus, 16GB' },
    ]
  }

  const initialValues: JumpHostFormValues = {
    hub: '',
    cluster: '',
    productStage: -1,
    vmSize: vmSizes[0].name,
    sshKey: '',
    cloudInit: `#cloud-config
apt:
  sources:
    azurecli.list:
      source: deb [arch=amd64] https://packages.microsoft.com/repos/azure-cli/ $RELEASE main
      keyid: BC528686B50D79E339D3721CEB3E94ADBE1229CF
    helm.list:
      source: deb [arch=amd64] https://baltocdn.com/helm/stable/debian/ all main
      keyid: 81BF832E2F19CD2AA0471959294AC4827C1A168A

package_update: true
package_upgrade: true

packages:
  - apt-transport-https
  - ca-certificates
  - gnupg-agent
  - software-properties-common
  - azure-cli
  - helm

runcmd:
  - az aks install-cli`,
  }

  const handleStageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    handleChange: any,
    setFieldValue: any
  ) => {
    const stage = stages[+event.target.value]
    setFieldValue('hub', stage.hub)
    setFieldValue('cluster', stage.cluster)
    handleChange(event)
  }

  const generateKey = (setFieldValue: any) => {
    setGeneratingSSHKey(true)
    generateSSHKey().then((keypair) => {
      setFieldValue('sshKey', keypair.publicKey)

      // download magic
      downloadString(
        keypair.privateKey,
        'jumphost-privatekey',
        'application/x-pem-file'
      )
      setGeneratingSSHKey(false)
    })
  }

  const validate = (values: JumpHostFormValues) => {
    const errors: FormikErrors<JumpHostFormValues> = {}
    if (!values.productStage || values.productStage === -1) {
      errors.productStage = 'Please select a product-stage.'
    }
    if (!values.sshKey) {
      errors.sshKey = 'Please add an ssh key.'
    }
    return errors
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Product-Stage</Form.Label>
            <Form.Select
              name="productStage"
              value={values.productStage}
              isInvalid={touched.productStage && !!errors.productStage}
              onChange={(event) =>
                handleStageChange(event, handleChange, setFieldValue)
              }
            >
              <option disabled key={-1} value={-1}>
                ---Select a product-stage----
              </option>
              {stages.map((stage: ProductStage, idx: number) => (
                <option key={idx} value={idx}>
                  {stage.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.productStage}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Hub</Form.Label>
            <Form.Control
              name="hub"
              value={values.hub}
              onChange={handleChange}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cluster</Form.Label>
            <Form.Control
              name="cluster"
              value={values.cluster}
              onChange={handleChange}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>VM Size</Form.Label>
            <Form.Select
              name="vmSize"
              value={values.vmSize}
              onChange={handleChange}
            >
              {vmSizes.map((size) => (
                <option
                  key={size.name}
                  value={size.name}
                >{`${size.name} (${size.spec})`}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <FormGroup className="mb-3">
            <Form.Label>Public SSH-Key</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                name="sshKey"
                as="textarea"
                rows={3}
                placeholder="ssh-rsa ..."
                value={values.sshKey}
                isInvalid={touched.sshKey && !!errors.sshKey}
                onChange={handleChange}
              />
              <Button
                variant={
                  !touched.sshKey || !errors.sshKey
                    ? 'outline-secondary'
                    : 'outline-danger'
                }
                size="sm"
                onClick={() => generateKey(setFieldValue)}
                disabled={isGeneratingSSHKey}
              >
                {isGeneratingSSHKey
                  ? 'Generating...'
                  : 'Generate keypair & download private key'}
              </Button>
              <Form.Control.Feedback type="invalid">
                {errors.sshKey}
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Text muted>
              Create an ssh key with{' '}
              <code>ssh-keygen -m PEM -t rsa -b 2048</code> and paste the
              content of your <code>.pub</code> file or use the "Generate
              key..." button. (The generation will be done inside the browser
              and we won't store any private keys)
            </Form.Text>
          </FormGroup>
          <FormGroup className="mb-3">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Advanced</Accordion.Header>
                <Accordion.Body>
                  <Form.Label>Cloud Init</Form.Label>
                  <Form.Control
                    name="cloudInit"
                    as="textarea"
                    rows={10}
                    placeholder=""
                    value={values.cloudInit}
                    isInvalid={touched.cloudInit && !!errors.cloudInit}
                    onChange={handleChange}
                  />
                  <Form.Text muted>
                    You can also change the cloud init configuration. See{' '}
                    <a
                      href="https://cloudinit.readthedocs.io/en/latest/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://cloudinit.readthedocs.io/en/latest/
                    </a>
                    for more information.
                  </Form.Text>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </FormGroup>
          <Button variant="primary" type="submit" disabled={provisioning}>
            {provisioning ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden
                />
                Provisioning...
              </>
            ) : (
              'Provision'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
