import React from 'react'
import { MsalProvider } from '@azure/msal-react'
import MsalInnerWrapper from './MsalInnerWrapper'
import { MsalRegion } from '../msal/msalRegion'
import { getMsalInstance } from '../msal/msalConfig'

interface MsalWrapperProps {
  children: React.ReactNode
  region: MsalRegion
}

export default function MsalWrapper(props: MsalWrapperProps) {
  const instance = getMsalInstance(props.region)

  const accounts = instance.getAllAccounts()
  if (accounts.length > 0) {
    if (!accounts[0].idTokenClaims?.iss?.includes(instance.getConfiguration().auth.authority)) {
      // manually clear msal cache so that a new token can be received
      sessionStorage.clear()
    } else {
      instance.setActiveAccount(accounts[0])
    }
  }

  return <MsalProvider instance={instance}>
    <MsalInnerWrapper>
      {props.children}
    </MsalInnerWrapper>
  </MsalProvider>
}