import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import React, { useEffect } from 'react'
import { msalLoginRequest } from '../msal/msalConfig'

interface MsalInnerWrapperProps {
  children: React.ReactNode
}

export default function MsalInnerWrapper(props: MsalInnerWrapperProps) {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(msalLoginRequest).catch((e: any) => { console.error(e) })
    }
  })

  if (!isAuthenticated) {
    return <>Redirecting...</>
  }

  return <>{props.children}</>
}