import { InteractionType } from '@azure/msal-browser'
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { Buffer } from 'buffer'

import { protectedResources } from './msal/msalConfig'
import { postStart } from './backend/functionApi'
import { acquireToken, getGraphClient } from './backend/graph'
import JumpHostForm, { JumpHostFormValues } from './components/JumpHostForm'
import ProductStage from './models/ProductStage'
import { Client, PageCollection, PageIterator } from '@microsoft/microsoft-graph-client'

const PRODUCT_STAGE_REGEX = /^([a-z][a-z0-9]{2,7}-[a-z0-9]{2,8})-(manager|developer|user)/
const HUB_CLUSTER_REGEX = /^CARIAD\.mega.([a-z0-9]+).([a-z]+).productstage-(manager|developer|user)$/

export default function JumpHost() {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const account = useAccount(accounts[0] || {})
  const [productStages, setProductStages] = useState<ProductStage[] | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [errorStatus, setErrorStatus] = useState('')
  const [possibleRun, setPossibleRun] = useState('')

  // load product-stages from graph API
  useEffect(() => {
    if (isAuthenticated && account && inProgress === 'none' && !productStages) {
      const graphClient = getGraphClient(
        {
          account,
          scopes: protectedResources.graphMeGroups.scopes,
          interactionType: InteractionType.Redirect
        },
        instance
      )

      async function fetchGroups() {
        try {
          const response: PageCollection = await graphClient.api(protectedResources.graphMeGroups.endpoint).get()
          const promises: Promise<ProductStage | null>[] = []
          const pageIterator = new PageIterator(graphClient, response, (data) => {
            promises.push(mapGroupToProductStage(data, graphClient))
            return true
          })
          await pageIterator.iterate()

          const productStages = (await Promise.all(promises)).filter((stage) => !!stage) as ProductStage[]
          productStages.sort((a, b) => a.name.localeCompare(b.name))
          setProductStages(productStages)
        } catch (err) {
          setErrorStatus(err instanceof Error ? err.message : JSON.stringify(err))
        }
      }
      fetchGroups()
    }
  })

  if (errorStatus) {
    return <span>{errorStatus}</span>
  }

  if (!productStages || !isAuthenticated) {
    return <>Loading groups...</>
  }

  if (possibleRun) {
    return <>
      🚀 The provisioning was successful and the jump host will be deployed into your resource group.
      You can watch the workflow here(*):&nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://github.com/vwdfive/megatron-paas/actions/runs/${possibleRun}`}>
        {`https://github.com/vwdfive/megatron-paas/actions/runs/${possibleRun}`}
      </a>
      <br /><br /><br />
      After the workflow was successful, you can log into the jumphost with the user <code>azure</code> and your private key.<br />
      The name of the jump host is listed inside the workflow.<br />
      <b>Keep in mind that the jump host will be automatically cleaned up at 11PM.</b>
      <br /><br /><br />
      <small>
        (*) There is sadly no good way to get the dispatched workflow so we did a best effort approach to find it for you.
        It could happen, that the linked workflow is not yours.
        If so, please switch to the&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://github.com/vwdfive/megatron-paas/actions/workflows/create-product-jumphost.yml`}>
          action overview
        </a>
        &nbsp;and search the workflow yourself or wait around 5 minutes and check whether the jump host is available in your resource group.
      </small>
    </>
  }

  const handleSubmit = (values: JumpHostFormValues) => {
    setLoading(true)
    const data = {
      hub: values.hub,
      cluster: values.cluster,
      productStage: productStages[values.productStage].name,
      vmSize: values.vmSize,
      sshKey: Buffer.from(values.sshKey).toString('base64'),
      cloudInit: Buffer.from(values.cloudInit).toString('base64')
    }

    acquireToken(instance, protectedResources.functionStart.scopes, account!, inProgress, (token) => {
      postStart(data, token)
        .then(async (resp) => {
          const body = await resp.json()
          setLoading(false)
          if (resp.status === 200) {
            setPossibleRun(body.possibleRun)
          } else {
            setErrorStatus(`ERROR: ${body.message}`)
          }
        }).catch(err => {
          setLoading(false)
          setErrorStatus(err.message ? err.message : JSON.stringify(err))
        })
    })
  }

  return <JumpHostForm handleSubmit={handleSubmit} stages={productStages} provisioning={isLoading} />
}

async function mapGroupToProductStage(group: any, graphClient: Client): Promise<ProductStage | null> {
  if (!group || !PRODUCT_STAGE_REGEX.test(group.displayName)) {
    return null
  }

  // extract product-stage name from group name
  const name = PRODUCT_STAGE_REGEX.exec(group.displayName)![1]

  // get all groups where the product-stage group is memberOf
  const memberOfResponse = await graphClient
    .api(protectedResources.graphGroupMemberOf.endpoint.replace('{id}', group.id))
    .get()

  if (!memberOfResponse || !memberOfResponse.value) return null

  // find the cluster group
  const clusterGroup = memberOfResponse.value
    .map((group: any) => group.displayName)
    .find((group: string) => HUB_CLUSTER_REGEX.test(group))

  if (!clusterGroup) return null

  // extract hub and cluster from cluster group
  const parts = HUB_CLUSTER_REGEX.exec(clusterGroup)!
  const hub = parts[1]
  const cluster = parts[2]

  return { name, hub, cluster }
}