import { msalConfigCn } from './msalConfigCn'
import { msalConfigNonCn } from './msalConfigNonCn'
import { MsalRegion } from './msalRegion'

const configs = [msalConfigNonCn, msalConfigCn]

export function getMsalInstance(region: MsalRegion) {
  const instance = configs.find((config) => config.region === region)?.instance

  if (!instance) throw new Error(`unsupported region: ${region}`)

  return instance
}

/**
 * Scopes to request during sign-in.
 */
export const msalLoginRequest = {
  scopes: ['User.Read'],
}

/**
 * Endpoints and scope used when obtaining an access token.
 */
export const protectedResources = {
  graphMeGroups: {
    endpoint: '/me/memberOf',
    scopes: ['User.Read', 'GroupMember.Read.All'],
  },
  graphGroupMemberOf: {
    endpoint: '/groups/{id}/memberOf',
    scopes: ['GroupMember.Read.All'],
  },
  functionStart: {
    endpoint: '/api/start',
    scopes: [],
  },
}
